import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '../../../core/services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthService } from '../../../core/services/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  formLogin : FormGroup = new FormGroup({
    email : new FormControl(null, [Validators.required]),
    password: new FormControl(null,[Validators.required])
  })

  loading : boolean = false;
  errorLogin : string = '';
  
  constructor(public dialogRef: MatDialogRef<LoginComponent>, private api : ApiService, private auth : AuthService){

  }

  getError(control: string) {
    let form : FormGroup = this.formLogin;
    const formControl : any = form.get(control)!;

    const errors : any = formControl.errors;
    if(errors?.required){
      return 'This field is required.';
    }
    if(errors?.[control] ?? false){
      return errors[control];
    }
    return '';
  }
  
  getUser(){
    
    const body = {};
    const method = 'user';
    this.api.get({body,method,headers:this.auth.getHttpHeaders()}).subscribe({
      next : (response) => {
        console.log(response);
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  login() {
    if(this.formLogin.invalid) return;

    this.getUser();

    this.loading = true;
    const body = this.formLogin.getRawValue();

    const method : string = "auth/login";
    
    this.api.post({body,method}).subscribe({
      next : (response)=>{
        this.loading = false;

        const result = response["Result"];
        if(result){
          const data = result.data;
          const token = result.token;
          this.auth.login(data,token);
          this.dialogRef.close({logged : true})
        }
        
      },
      error : (error : string) => {
        
        if(error == 'not_active'){
          this.dialogRef.close({confirm_mode: true,body: body})
        }
        
        this.loading = false;
      }
    })
  }

}
