<header [ngStyle]="{
  'background-image': getRoute(activeRoute).image ? 'url('+getRoute(activeRoute).image+')' : 'unset',
  'height': getRoute(activeRoute).image ?  (getRoute(activeRoute)?.height ?? null) : '75px',
  'background-position': getRoute(activeRoute).position ?? null
 }">
  <mat-toolbar [ngClass]="{'active': isActive()}">
    <app-website-title style="margin-right: 1em;"></app-website-title>
    <nav>

      @for(rout of getRoutes() ; track $index){
        @if(getRoute(rout).display ?? false){
          <a [href]="rout" class="tab-nav" [ngClass]="{'active':activeRoute.endsWith(rout)}">
            {{getRoute(rout).display}}
          </a>
        }
      }
      @if(!isHidding()){

        <div [ngStyle]="{'display': isLogged() ? 'none':'flex'}" style="gap: 1em;">
          <button type="button"  (click)="openLogin()" class="login-register">
            Login
          </button>
          <div class="spliter-wrapper">
            <div class="spliter"></div>
          </div>
          <button type="button" (click)="openRegister()" class="login-register">
            Register
          </button>
        </div>
        @if(isLogged()){
          <a href="/dashboard">
            <button class="mon-logo-user" [ngClass]="{'active': isActive()}" mat-icon-button>
              <img width="32" height="32" src="/assets/icons/my other nest logos_icono1.png" alt="logo">
            </button>
          </a>
        }
      }
    </nav>
    <div class="button-nav">
      <button type="button" class="button-menu-header" [matMenuTriggerFor]="menu" >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-menu #menu="matMenu"  class="nav-oculta" backdropClass="cdk-header">
    @for(rout of getRoutes() ; track $index){
      @if(getRoute(rout).display ?? false){
        <a [href]="rout" class="tab-nav" [ngClass]="{'active':activeRoute.endsWith(rout)}">
          {{getRoute(rout).display}}
        </a>
      }
    }
    <div style="margin-bottom: 0.5rem; display: flex; justify-content: center;align-items: center; flex-direction: row;">
      @if(!isHidding()){

        @if(!isLogged()){
          <button type="button" (click)="openLogin()" class="login-register">
            Login
          </button>
          <div class="spliter-wrapper">
            <div class="spliter"></div>
          </div>
          <button type="button" (click)="openRegister()" class="login-register">
            Register
          </button>
        }@else {
          <a href="/dashboard">
            <button class="mon-logo-user" mat-icon-button>
              <img width="16" height="16" src="/assets/icons/my other nest logos_icono1.png" alt="logo">
            </button>
          </a>
        }
      }
    </div>
  </mat-menu>
  @if(!activeRoute || activeRoute == '/' || activeRoute=='/rent-rooms-dublin'){
    <article class="search-home">

      @if(activeRoute != '/rent-rooms-dublin'){
        <h2 style="font-family: Source Sans Pro; font-weight: 700;">FIND A PLACE TO FEEL LIKE HOME</h2>
        <h3 style="font-family: Montserrat;">Accommodations for modern people in Dublin</h3>
      }
      <section [ngStyle]="{'width': activeRoute == '/rent-rooms-dublin' ? '90%' : '60%'}" style="display: flex; flex-wrap: wrap; gap: 1em; justify-content: center;">
        <mat-form-field style="flex-grow: 1;">
          <mat-label>Arrive</mat-label>
          <input (click)="picker.open()" (focus)="picker.open()" readonly="true" [min]="minDate" [formControl]="start" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker (closed)="picker2.open()" [panelClass]="'cdk-dateBackdrop'" #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="flex-grow: 1;">
          <mat-label>Depart</mat-label>
          <input (click)="picker2.open()" (focus)="picker2.open()" readonly="true" [min]="startDate" [formControl]="start" matInput [matDatepicker]="picker2">
          <mat-datepicker-toggle matIconPrefix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker [panelClass]="'cdk-dateBackdrop'" #picker2></mat-datepicker>
        </mat-form-field>
        <div style="display: flex; justify-content: center; align-items: center; gap: 1em; height: 55px;">
          @if(activeRoute == '/rent-rooms-dublin'){
            <button aria-label="Filters button" type="button" (click)="onFilters = !onFilters" style="width: 60px; cursor: pointer;  height: 100%; border-radius: 4px; outline: none; border: none; box-shadow: 3px 5px 7px -1px rgba(0,0,0,0.5);">
              <mat-icon>
                tune
              </mat-icon>
            </button>

            <app-filters-find-home [ngStyle]="{'top':(calcTop(getRoute(activeRoute)?.height)) ?? '24rem'}" [ngClass]="{'hidden':!onFilters}" class="menu-filters"></app-filters-find-home>
          }
          <button type="button" style="cursor: pointer; height: 100%; padding-left: 2rem;  padding-right: 2rem; font-weight: 600; box-shadow: 3px 5px 7px -1px rgba(0,0,0,0.5);
          font-size: 1rem;  color: white; outline: none; border: none; border-radius: 4px; background-color: #db332a;">
            Search
          </button>
        </div>
      </section>
    </article>
  }
</header>