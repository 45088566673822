import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsideDashboardComponent } from "../aside-dashboard/aside-dashboard.component";
import { RouterModule } from '@angular/router';
import { HeaderDashboardComponent } from "../header-dashboard/header-dashboard.component";
import { FooterComponent } from "../../footer/footer.component";

@Component({
  selector: 'app-skeleton-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    AsideDashboardComponent,
    RouterModule,
    HeaderDashboardComponent,
    FooterComponent
],
  templateUrl: './skeleton-dashboard.component.html',
  styleUrls: ['./skeleton-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonDashboardComponent {


  toggle: boolean = true;

  toggleMenu(event: boolean) {
    this.toggle = event;
  }
}
