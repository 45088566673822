<div style="display: flex; flex-direction: row; background-color: white;">

  <section style="width: 510px; background-position: center center; background-size: cover; background-repeat: no-repeat;
  background-image: url('/assets/images/home/my-other-nest-home-30.jpg');">

  </section>
  <section  style="display: flex; flex-direction: column; width: 100%;">
    <div class="title-modal" mat-dialog-title>
      <h4>REGISTER</h4>
      <h3 style="margin-bottom: 0.5em;">JOIN US</h3>
      <mat-progress-bar  [mode]="loading ? 'indeterminate' : 'determinate'"></mat-progress-bar>
      <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>

      <app-sing-up-form (emailVerification)="openLogin()" (submiting)="loading = $event;" (error_signup)="showError($event)"><span class="error">{{error}}</span>
        <mat-dialog-actions>
          <button [disabled]="loading" type="submit" class="login-button"  mat-button>
            Register
          </button>
          <p style="color: #415273; margin-bottom: 0; margin-top: 1rem; width: 100%; text-align: center;">Do you already have an account? <a  [mat-dialog-close]="{login:true}" style="color: #db332a; text-decoration: none; cursor: pointer;">Log In</a></p>
        </mat-dialog-actions>
      </app-sing-up-form>
    </mat-dialog-content>
    
  </section>


</div>
