import { Routes } from '@angular/router';
import { SkeletonComponent } from './layouts/skeleton/skeleton.component';
import { SkeletonDashboardComponent } from './layouts/dashboard/skeleton-dashboard/skeleton-dashboard.component';
import { authenticationGuard } from './core/services/authentication.guard';

export const routes: Routes = [
  {
    path: '',
    component: SkeletonComponent,
    loadChildren: () => import('./modules/main.module').then((m)=>m.MainModule),
  },
  {
    path: 'dashboard',
    component: SkeletonDashboardComponent,
    canMatch: [authenticationGuard],
    loadChildren : () => import('./modules/dashboard/dashboard.module').then((m)=>m.DashboardModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch : 'full'
  }
];
