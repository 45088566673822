<footer>
  <section class="apart" style="max-width: 400px; padding: 0; padding-left: 1rem;">
    <app-website-title></app-website-title>
    <div class="container-red">
      @for(red of social_red ; track $index){
        <a [href]="red.link">
          <img [alt]="red.title" [src]="red.image">
        </a>
      }
    </div>
    <div class="description">
      <address style=" max-width: 180px;">
        {{direction}}
      </address>
      @for(phone of phones ; track $index){
        <a  style="text-decoration: none; color: inherit;" href="tel:{{phone}}">{{phone}}</a><br>
      }
      @for(e of email ; track $index){
        <a  style="text-decoration: none; color: inherit;" href="mailto:{{e}}">{{e}}</a><br>
      }
    </div>
  </section>
  <section class="apart">
    <h3>MY OTHER NEST</h3>
    <ul>
      @for(route of routes_myothernest ; track $index){
        <li>
          <a [href]="route.link">
            {{route.text}}
          </a>
        </li>
      }
    </ul>
  </section>

  <section class="apart">
    <h3>LANDLORDS</h3>
    <ul>
      @for(route of routes_landlords ; track $index){
        <li>
          <a [href]="route.link">
            {{route.text}}
          </a>
        </li>
      }
    </ul>
  </section>

  <section class="apart">
    <h3>HELP CENTER</h3>
    <ul>
      @for(route of routes_helpcenter ; track $index){
        <li>
          <a [href]="route.link">
            {{route.text}}
          </a>
        </li>
      }
    </ul>
  </section>
  <div class="footer-2">
    <p style="font-family: Montserrat; font-size: 0.8rem;">© My Other Nest 2024	</p>
    <div style="display: flex; font-weight: 400; font-size: 0.8rem; font-family: Montserrat; align-items: center; color: white; gap: 1em; flex-wrap: wrap;">
      <img alt="copyright shield" height="30px" src="/assets/images/footer/footer-copyright-shield.png">
      Secure payment methods:
      <img height="20px" style="max-width: 90vw;" alt="payment copyright" [src]="image_payments">
    </div>
  </div>
</footer>
