import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-website-title',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './website-title.component.html',
  styleUrl: './website-title.component.scss'
})
export class WebsiteTitleComponent {

}
