import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterModule } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-aside-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,MatIconModule,
    RouterModule

  ],
  templateUrl: './aside-dashboard.component.html',
  styleUrls: ['./aside-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideDashboardComponent {

  @Input() opened : boolean = true;
  @Output() toggle :  EventEmitter<any> = new EventEmitter();


  readonly LINKS = [
    {
      link : "booking-requests",
      name : "Booking requests",
      icon : "bookmark_check"
    },
    {
      link : 'listings',
      name : "Listings",
      icon : 'list',
    },
    {
      link : "bookings",
      name : "Bookings",
      icon : "library_books"
    },
    {
      link : "profile",
      name : "Profile",
      icon : "person"
    }
  ]

  readonly BUTTONS = [
    
    {
      value : "logout",
      name: "Logout",
      icon: "logout"
    }
  ]

  urlActive : string = "";
  startView : boolean = false;

  constructor(private  router: Router, private api : ApiService, private auth : AuthService, private changes : ChangeDetectorRef){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlActive = event.url;
      }
    });
  }

  ngAfterViewInit(){
    setTimeout(()=>this.startView = true);
    this.changes.detectChanges();
  }


  onclick(but : any){
    if(but.value == 'logout'){
      window.location.href = '/'
      const REST_API = this.api.post({method:'auth/logout'}).subscribe({
        next: (response)=>{
          this.auth.logout();
          REST_API.unsubscribe();
        },
        error: (error) => {
          this.auth.logout();
          REST_API.unsubscribe();
        }
      });
    }
  }
  close(){
    this.toggle.emit(this.opened = false);
  }

  toggleMenu(){
    this.toggle.emit(this.opened = !this.opened);
  }

}
