<div class="center-filters">
  <section class="filter-group">
    <strong>Size</strong>
    <div class="filters">
      <mat-form-field>
        <mat-label>{{numOfBeds[0]}}</mat-label>
        <mat-select disableRipple>
          @for(selectFilter of numOfBeds ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{numOfRooms[0]}}</mat-label>
        <mat-select disableRipple>
          @for(selectFilter of numOfRooms ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{roomType[0]}}</mat-label>
        <mat-select disableRipple>
          @for(selectFilter of roomType ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
  </section>
  <section class="filter-group">
    <strong>Price</strong>
    <div class="filters">
      <mat-form-field>
        <mat-label>{{minAr[0]}}</mat-label>
        <mat-select disableRipple>
          @for(selectFilter of minAr ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{maxAr[0]}}</mat-label>
        <mat-select disableRipple>
          @for(selectFilter of maxAr ; track $index){
            @if($index == 0){
              <mat-option>{{selectFilter}}</mat-option>
            }@else {
              <mat-option value="{{selectFilter}}">{{selectFilter}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
  </section>
  <section #divFilterAmenities class="filter-group">
    <strong>Amenities</strong>
    <div style="display: flex; flex-direction: column; max-width: 1133px; width: 100%;">
      <div class="filters" style="width: 100%;">
        @for(amen of amenities ; track $index){
          <mat-checkbox [value]="amen">{{amen}}</mat-checkbox>
        }
      </div>

     
      <div class="filters" style="width: 100%;" [ngStyle]="{'visibility' : divFilterAmenities.classList.contains('expanded') ? 'visible' : 'hidden'}">
          @for(facil of moreAmenities ; track $index){
            <mat-checkbox [value]="facil">{{facil}}</mat-checkbox>
          }
      </div>
    </div>
    <button (click)="more(divFilterAmenities)" type="button" style="color: #db332a;" mat-button>
      More 
      <mat-icon>more_vert</mat-icon>
    </button>
    
    

  </section>
  <section #divFilterFacilities  class="filter-group">
    <strong>Facilities</strong>
    <div style="display: flex; flex-direction: column; max-width: 1133px; width: 100%;">
      <div class="filters" style="width: 100%;">
        @for(facil of facilities ; track $index){
          <mat-checkbox [value]="facil">{{facil}}</mat-checkbox>
        }
      </div>
      <div class="filters" style="width: 100%;" [ngStyle]="{'visibility' : divFilterFacilities.classList.contains('expanded') ? 'visible' : 'hidden'}">
          @for(facil of moreFacilities ; track $index){
            <mat-checkbox [value]="facil">{{facil}}</mat-checkbox>
          }
      </div>
    </div>
    <button (click)="more(divFilterFacilities)" type="button" style="color: #db332a;" mat-button>
      More 
      <mat-icon>more_vert</mat-icon>
    </button>
  </section>

  <section class="button-selection">
    <button type="button" mat-button>Reset</button>
    <button type="button" style="color: #db332a;" mat-button>Apply Filters</button>
  </section>
</div>
