import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WebsiteTitleComponent } from "../../../components/website-title/website-title.component";

@Component({
  selector: 'app-header-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    WebsiteTitleComponent
],
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDashboardComponent {

  @Input() opened: boolean = true;
  @Output() toggle: EventEmitter<any> = new EventEmitter();


  toggleMenu(){
    this.toggle.emit(this.opened = !this.opened);
  }
}
