import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SingUpFormComponent } from "./sing-up-form/sing-up-form.component";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatProgressBarModule } from '@angular/material/progress-bar'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';




@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatButtonModule,
    SingUpFormComponent,
    MatIconModule,
    FormsModule,ReactiveFormsModule,
    MatProgressBarModule,
    MatFormFieldModule, MatInputModule
],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent {


  @Input()profile : boolean = false;

  loading : boolean = false;
  error : string = '';

  constructor( private dialogRef: MatDialogRef<RegisterComponent>,){

  }

  showError(error : string) {
    this.error = error;
  }

  openLogin() {
    this.dialogRef.close({login: true})
  }

}
