<div style="display: flex; flex-direction: row; background-color: white;">

  @if(option == MODE_RESTORE.FIRST){
    <form [formGroup]="form" (submit)="restorePassword()" style="display: flex; flex-direction: column; width: 100%;">
      <div class="title-modal" mat-dialog-title>
        <h4>FORGOT PASSWORD</h4>
        <mat-progress-bar [mode]="disabledConfirm ? 'indeterminate' : 'determinate'"></mat-progress-bar>
        <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-dialog-content>
        <p style="color: #415273;">Please enter your email address. You will receive a link to create a new password via email.</p>
        <mat-form-field style="width: 100%;">
          <mat-label>Email</mat-label>
          <input formControlName="email" type="text" matInput  />
          @if(form.get('email')?.hasError){
            <mat-error style="font-family: Montserrat;">
              {{getError('email')}}
            </mat-error>
          }
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        
        <button type="submit" [disabled]="disabledConfirm" class="login-button"  mat-button>Submit</button>
      </mat-dialog-actions>
    </form>
  }@else if (option == MODE_RESTORE.SEND_CODE) {
    <form style="display: flex; flex-direction: column; width: 100%;" [formGroup]="form" (submit)="restorePassword()" method="post">
      <div class="title-modal" mat-dialog-title>
        <h4>FORGOT PASSWORD</h4>
        <mat-progress-bar  [mode]="disabledConfirm ? 'indeterminate' : 'determinate'"></mat-progress-bar>
        <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-dialog-content style="    text-align: center;
      justify-content: center;
      align-items: center;">
        <p style="font-family: Montserrat; ">
          Please enter the code sent to your email to confirm your account or resend another one
        </p>
        <div style="display: flex; flex-wrap: wrap; gap: 0.5em;" id="codes-container">
          @for(c of codes ; track $index){
            <input (click)="cleanCode($index)" (paste)="pasteCode($event)" (input)="validateCode($event,$index)" class="code-class" type="number" formControlName="code{{$index}}">
          }
        </div>
        
        <mat-error style="font-family: Montserrat; color: var(--mon-red);">
          {{errorConfirm}}
        </mat-error>
        
        <button  style="margin-top: 1em;" [disabled]="disabledConfirm" mat-button class="confirm-button login-button" type="submit">
          Confirm
        </button>
      </mat-dialog-content>
    </form>
  }@else if(option == MODE_RESTORE.SEND_PASSWORD){
    <form [formGroup]="form" (submit)="restorePassword()" style="display: flex; flex-direction: column; width: 100%;">
      <div class="title-modal" mat-dialog-title>
        <h4>FORGOT PASSWORD</h4>
        <mat-progress-bar  [mode]="disabledConfirm ? 'indeterminate' : 'determinate'"></mat-progress-bar>
        <button mat-dialog-close type="button" title="Close modal" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-dialog-content>
        <p style="color: #415273;">Please enter a new password.</p>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input formControlName="password" type="password" matInput  />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Repeat password</mat-label>
          <input formControlName="repeat_password" type="password" matInput  />
          @if(form.get('repeat_password')?.hasError){
            <mat-error style="font-family: Montserrat;">
              {{getError('repeat_password')}}
            </mat-error>
          }
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        @if(confirmed){
          <p style="font-family: Montserrat; width: 100%;">
            Your password has been successfully restored. You can now <a [mat-dialog-close]="true" style="cursor: pointer; color: var(--mon-red); text-decoration: none;">log in</a>
          </p>
        }
        <button type="submit" [disabled]="disabledConfirm || confirmed" class="login-button"  mat-button>Submit</button>
      </mat-dialog-actions>
    </form>
  }

  


</div>
