import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WebsiteTitleComponent } from "../../components/website-title/website-title.component";
import { INFO_MYOTHERNEST } from '../../data/const/myothernest.info';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [WebsiteTitleComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {


  readonly social_red = INFO_MYOTHERNEST.social_media;
  readonly direction = INFO_MYOTHERNEST.office.address + ", "+INFO_MYOTHERNEST.office.county+" "+INFO_MYOTHERNEST.office.number;
  readonly phones = INFO_MYOTHERNEST.phones;
  readonly email = INFO_MYOTHERNEST.email;

  readonly routes_myothernest = [
    {
      text : "About Us",
      link : "/website-rooms-for-rent-dublin"
    },
    {
      text : "Why My Other Nest?",
      link : "/platform-rooms-rent-dublin"
    },
    {
      text : "Find a home",
      link : "/rent-rooms-dublin"
    },
    {
      text : "Nester's experiences",
      link : "/roommates-nesters-experiences"
    },
    {
      text : "Blog",
      link : ""
    }
  ]

  readonly routes_landlords = [
    {
      text : "Landlord area",
      link : "/landlord-area"
    }
  ]

  readonly routes_helpcenter = [
    {
      text : "How it works?",
      link : "/how-we-rent-rooms-in-dublin"
    },
    {
      text : "FAQS",
      link : "/faqs"
    },
    {
      text : "Contact us",
      link : "/contacts-room-rental-platform-in-dublin"
    }
  ]

  readonly image_payments = "/assets/images/footer/footer-copyright-payments.png";

}
