import { Component } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-filters-find-home',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule,MatIcon,MatCheckboxModule, MatButtonModule],
  templateUrl: './filters-find-home.component.html',
  styleUrl: './filters-find-home.component.scss'
})
export class FiltersFindHomeComponent {



  readonly numOfBeds = ["n. of bedrooms",1,2,3,4,5,6,7,8,9,10];
  readonly numOfRooms = ["n. of rooms",1,2,3,4,5,6,7,8,9,10];
  readonly roomType = ["Room Type","Entire Place","Private Room","Shared Room"];
  readonly minAr = ["Min.", ...Array.from({length: 16}, (_, i) => ((i+1 )* 10))];
  readonly maxAr = ["Max.",...Array.from({length: 16}, (_, i) => ((i+1 )* 10))];

  readonly amenities = ["Air Conditioning", "Barbecue Area","Bed sheets", "Cleaning products", "Dishwasher","Dryer"];
  readonly facilities = ["Beachside","Farmacy","Free Parking", "Markets", "Park", "Playground"]

  readonly moreAmenities = ["Equipped kitchen","Garden","Gym","Laundry","Microwave","Professional cleaning of common areas","Professional disinfection COVID-19",
    "Sauna","Swimming Pool", "TV Cable","Washing machine","Wi-Fi"]
  
  readonly moreFacilities = ["Reception","Security","Shops"]


  more(element : HTMLElement){

    element.classList.toggle('expanded');

  }

}
