
<mat-drawer-container style="z-index: 99;" dir="ltr" style="background: inherit;" >
    <mat-drawer [ngStyle]="{'display': startView ? null : 'none'}" mode="side" (closed)="close()" [opened]="opened && startView"
    style="max-width: 200px; z-index: 1;  border-radius: 0; width: 100%;  background-color: white;" #drawer>
        <!-- <header style="padding: 0.8em; justify-content: space-between; align-items: center; display: flex; font-weight: 500; font-size: large; ">
            Options
            <button (click)="toggleMenu()" mat-icon-button>                
                <mat-icon matIconSuffix>close</mat-icon>
            </button>
        </header> -->

        <section class="drawer-section">
            @for(but of LINKS ; track $index){
                <a [routerLink]="but.link" style="text-decoration: none; color: inherit;">
                    <button [ngStyle]="{'background-color': urlActive.endsWith(but.link) ? '#d7d7d7' : ''}" mat-flat-button>
                        <mat-icon matIconPrefix>{{but.icon}}</mat-icon> 
                        <span style="width: 135px; display: flex;">{{but.name}}</span>
                    </button>
                </a>
            }
            
        </section>
        <section class="drawer-section">
            @for(but of BUTTONS ; track $index){
                <a style="text-decoration: none; color: inherit;">
                    <button (click)="onclick(but)" mat-flat-button>
                        <mat-icon matIconPrefix>{{but.icon}}</mat-icon> 
                        <span style="width: 135px; display: flex;">{{but.name}}</span>
                    </button>
                </a>
            }
        </section>
    </mat-drawer>
    <mat-drawer-content>
        <mat-drawer-container style="background-color: inherit;">
            <main style="min-height: 41.3dvh;">
                <router-outlet></router-outlet>
            </main>
        </mat-drawer-container>
    </mat-drawer-content>
    
</mat-drawer-container>

